//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CarrelloElement from "./carrello_element_desktop";
import cart_mixin from "../mixin/cart_mixin";
import concludi_mixin from "../mixin/concludi_ordine_mixin";
import date_picker_mixin from "../mixin/date_picker_mixin";
import DialogConferma from "../conferma_ordine_dialog.vue";

export default {
  components: {
    CarrelloElement,
    DialogConferma,
  },
  props: {
    carrello: Array,
  },
  mixins: [cart_mixin, concludi_mixin, date_picker_mixin],
};
