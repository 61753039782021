//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DialogProdotto from '@/components/lista/desktop/dialog_inserimento/listino_dettaglio_prodotto_desktop'
import carrello_element_mixin from "../mixin/carrello_element_mixin"
export default {
    components: {
        DialogProdotto
    },

    props: {
        item: Object
    },
    mixins:[carrello_element_mixin]
}
